import fetch from "./xhr/index";

/**
 * 服务商注册接口
 * @param
 */
export const sign = param => fetch("POST", "/admin/registerService", param);

/**
 * 服务商注册手机验证码生成
 * @param
 */
export const sms = param => fetch("GET", "/ums/umsPhoneCode/phoneCode", param);

/**
 * 创建公司
 * @param
 */
export const found = param => fetch("POST", "/company/found", param);

/**
 * 公司档案详情
 * @param
 */
export const details = param => fetch("POST", "/company/details", param);

/**
 * 公司资料修改
 * @param
 */
export const update = param => fetch("POST", "/company/update", param);


/**
 * 个人详细信息
 * @param
 */
export const companyInfo = param => fetch("POST", "/admin/companyInfo", param);

/**
 * 员工完善个人资料
 * @param
 */
export const persUpdate = param => fetch("POST", "/admin/update", param);

/**
 * 服务商个人资料修改
 * @param
 */
export const personUpdate = param => fetch("POST", "/admin/update", param);

/**
 * 员工注册
 * @param
 */
export const registerCustomer = param => fetch("POST", "/admin/registerCustomer", param);

/**
 * 员工列表
 * @param
 */
export const list = param => fetch("POST", "/admin/list", param);

/**
 * 员工列表详情修改
 * @param
 */
export const listUpdate = param => fetch("GET", "/admin/list/update", param);

/**
 * 员工详情修改记录
 * @param
 */
export const edit = param => fetch("GET", "/ums/umsAdminUpdateRecord/list", param);

/**
 * 员工列表详情
 * @param
 */
export const basic = param => fetch("GET", "/admin/list/basic", param);

/**
 * 调查问卷填写
 * @param
 */
export const questionnaire = param => fetch("POST", "/ums/umsQuestionnaire/found", param);

/**
 * 调查问卷查看
 * @param
 */
export const view = param => fetch("GET", "/ums/umsQuestionnaire/view", param);

/**
 * 员工已有账号登录
 * @param
 */
export const loginFromCustomer = param => fetch("POST", "/admin/loginFromCustomer", param);




