<!-- 个人资料 -->
<template>
  <div class="container">
    <div class="body">
      <span class="line"></span>
      <div class="tit">完善个人资料</div>
      <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
        <el-form-item label="用户名称" prop="nickName">
          <el-input
            v-model="form.nickName"
            placeholder="请输入用户名"
            class="common-screen-input_100"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <span>{{ form.phone }}</span>
        </el-form-item>
        <el-form-item label="微信号">
          <el-input
            v-model="form.wxId"
            placeholder="请输入"
            class="common-screen-input_100"
            :clearable="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="form.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日">
          <el-date-picker
            v-model="form.birthDay"
            type="date"
            class="common-screen-input iptW"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="地址">
          <el-cascader
            :props="props"
            v-model="form.addressId"
            class="add-ipt"
            clearable
            placeholder="省市区"
          ></el-cascader>
          <el-input
            v-model="form.address"
            class="common-screen-input_55"
            placeholder="请输入具体地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡号">
          <el-input
            class="common-screen-input iptW"
            v-model="form.bankCard"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item class="com">
          <el-button @click="() => { submitForm() }" class="createCom">下一步</el-button>
        </el-form-item>
      </el-form>
      <!-- <div><el-radio v-model="radio" label="1">接受《帮客智能客服管理系统公司协议》</el-radio></div> -->
      <div class="foot">有问题联系管理员</div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../../utils/index.js";
import { sysArea } from "../../service/common.js";
import { companyInfo, persUpdate, } from "../../service/login.js";

export default {
  name: "createCom",
  components: {},
  props: {},
  data() {
    return {
      Config,
      form: {
        nickName: "", //用户昵称
        phone: this.$route.query.phone, //手机号
        wxId: "", //公司名称
        sex: 1, //	0：女 1：男
        addressId: [], //公司所属地区ID
        address: "", //公司地址
        birthDay: "",
        bankCard: ""
      },
      formRules: {
        nickName: [
          { required: true, message: "请输入用户名称", trigger: "blur" }
        ]
      },
      props: {
        //地区
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId;
          if (level == 0) {
            typeId = 0;
          } else {
            typeId = value;
          }
          let resData = (await sysArea({ id: typeId })).data;
          let nodes = resData.map((item) => ({
            value: item.id + "",
            label: item.name,
            leaf: level >= 2
          }));
          resolve(nodes);
        }
      },
      loading2: false,
      perInfo: {}
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPersInfo();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getPersInfo() {
      this.loading2 = true;
      const { data } = await companyInfo();
      this.perInfo = data;
      this.form.nickName = data.nick_name;
      this.form.wxId = data.wx_id;
      this.form.sex = data.sex;
      this.form.birthDay = data.birth_day;
      if(data.address_id){
        let addArr = data.address_id.split(',')
        this.form.addressId = addArr;
      }
      this.form.address = data.address;
      this.form.bankCard = data.bank_card;
      this.loading2 = false;
    },
    submitForm() {
      this.$refs["form"].validate(async (value) => {
        if (value) {
          if(!this.form.address){
            delete this.form.address
          }
          if(!this.form.bankCard){
            delete this.form.bankCard
          }
          if(!this.form.wxId){
            delete this.form.wxId
          }
          this.form.birthDay ? this.form.birthDay : (delete this.form.birthDay)
          this.form.sex||this.form.sex==0 ? this.form.sex : (delete this.form.sex)
          let params = { ...this.form };
          if(params.addressId&&params.addressId.length){
            params.addressId = params.addressId.join(",")
          }else{
            delete params.addressId
          }
          params.id = this.$route.query.id
          params.companyId = this.$route.query.comId
          const res = await persUpdate(params);
          if (res.data) {
            this.$message.success("操作成功");
          }
          if (this.perInfo.questionnaire == 0) { //无调查问卷,填写
            this.$router.push({ path: "/pers-ques",query:{id:this.perInfo.id,flag: false,comId: this.$route.query.comId} });
          }
        } else {
          console.log('false');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  padding: 96px 291px;
  width: 100%;
  // height: 100vh;
  background-size: 100% 100%;
  background-color: #ebf0ff;
  background-image: url("../../assets/images/login_background.png");
  background-repeat: no-repeat;
  .body {
    box-sizing: border-box;
    padding: 80px;
    width: 100%;
    // height: 100%;
    background: #fff;
    .line {
      display: block;
      width: 40px;
      height: 6px;
      margin-bottom: 8px;
      background: #1890ff;
      border-radius: 8px;
    }
    .tit {
      margin-bottom: 80px;
      color: rgba(16, 16, 16, 100);
      font-size: 32px;
      text-align: left;
    }
    /deep/ .el-form {
      width: 600px;
      margin: 0 auto;
      .el-form-item__label {
        font-size: 16px;
        text-align: center;
      }
      .el-form-item__content {
        text-align: left;
        .createCom {
          width: 100%;
          height: 48px;
          line-height: 24px;
          background-color: #1890ff !important;
          color: #fff;
          span {
            font-size: 24px;
          }
        }
      }
      .com {
        margin-top: 80px;
      }
    }
    /deep/ .el-input__icon{
      height: 100%;
    }
    /deep/ .common-screen-input_55 {
      margin-left: 8px;
    }
    /deep/ .el-select {
      width: 216px;
    }
    /deep/ .el-input__inner {
      height: 48px;
    }
    .tip {
      font-size: 12px;
    }
    .upload-title {
      font-size: 18px;
      color: #101010;
    }
    .uploadBtn-container {
      position: relative;
      z-index: 2;
      width: 500px;
      height: 240px;
      line-height: 240px;
      border: 1px solid rgba(220, 223, 230, 100);
      color: #1890ff;
      font-size: 16px;
      background: #fff;
      background: url("~@/assets/images/business.png") no-repeat center;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      position: relative;
      .up {
        display: inline-block;
        margin-top: 30px;
      }
      .iconfont {
        position: absolute;
        top: -9%;
        left: 50%;
        transform: translate(-50%);
        font-size: 64px;
        color: #1890ff;
      }
      .uploadBtn-input {
        width: 500px;
        height: 240px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
  .foot {
    color: rgba(153, 153, 153, 100);
    font-size: 16px;
    padding-left: 100px;
  }
  .fileList-container {
    width: 500px;
    height: 240px;
    .fileList-item {
      display: flex;
      justify-content: space-between;
      width: 320px;
      .item-name {
        font-size: 12px;
        color: #333;
        .commonPicture-container {
          /deep/ .fileImg {
            width: 500px;
            height: 240px;
            margin-top: 0;
            .el-image__inner {
              // width: auto;
              width: 500px;
              height: 240px;
            }
          }
        }
      }
      .deleteBtn {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
  .add-ipt {
    // width: 230px;
    width: 45%;
  }
  .common-screen-input {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .common-screen-input_55 {
    // width: 259px;
    width: 53%;
  }
}
</style>
